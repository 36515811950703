import _definePage_default_0 from '/app/src/pages/index/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/app/src/pages/index.vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/app/src/pages/[...all].vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/app/src/pages/auth.vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'default-layout',
    component: () => import('/app/src/pages/index.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'main-dashboard',
        component: () => import('/app/src/pages/index/index.vue'),
        /* no children */
        meta: {
          "requiresAuth": true
        }
      },
  _definePage_default_0
  ),
      {
        path: 'absence-histories',
        /* internal name: '//absence-histories' */
        /* no component */
        children: [
          {
            path: ':absenceHistory',
            /* internal name: '//absence-histories/[absenceHistory]' */
            /* no component */
            children: [
              {
                path: 'detail',
                name: '//absence-histories/[absenceHistory]/detail',
                component: () => import('/app/src/pages/index/absence-histories/[absenceHistory]/detail.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'action-list',
        /* internal name: '//action-list' */
        /* no component */
        children: [
          {
            path: '',
            name: '//action-list/',
            component: () => import('/app/src/pages/index/action-list/index.vue'),
            /* no children */
          },
          {
            path: ':actionList',
            /* internal name: '//action-list/[actionList]' */
            /* no component */
            children: [
              {
                path: 'task-list',
                /* internal name: '//action-list/[actionList]/task-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//action-list/[actionList]/task-list/',
                    component: () => import('/app/src/pages/index/action-list/[actionList]/task-list/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':taskList',
                    /* internal name: '//action-list/[actionList]/task-list/[taskList]' */
                    /* no component */
                    children: [
                      {
                        path: 'execute',
                        name: '//action-list/[actionList]/task-list/[taskList]/execute',
                        component: () => import('/app/src/pages/index/action-list/[actionList]/task-list/[taskList]/execute.vue'),
                        /* no children */
                      },
                      {
                        path: 'view',
                        name: '//action-list/[actionList]/task-list/[taskList]/view',
                        component: () => import('/app/src/pages/index/action-list/[actionList]/task-list/[taskList]/view.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: ':result',
            /* internal name: '//action-list/[result]' */
            /* no component */
            children: [
              {
                path: 'form',
                name: '//action-list/[result]/form',
                component: () => import('/app/src/pages/index/action-list/[result]/form.vue'),
                /* no children */
              },
              {
                path: 'survey',
                name: '//action-list/[result]/survey',
                component: () => import('/app/src/pages/index/action-list/[result]/survey.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'activity-list',
        /* internal name: '//activity-list' */
        /* no component */
        children: [
          {
            path: '',
            name: '//activity-list/',
            component: () => import('/app/src/pages/index/activity-list/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'analytics',
        /* internal name: '//analytics' */
        /* no component */
        children: [
          {
            path: '',
            name: '//analytics/',
            component: () => import('/app/src/pages/index/analytics/index.vue'),
            /* no children */
          },
          {
            path: 'absence',
            /* internal name: '//analytics/absence' */
            /* no component */
            children: [
              {
                path: 'details',
                name: '//analytics/absence/details',
                component: () => import('/app/src/pages/index/analytics/absence/details.vue'),
                /* no children */
              },
              {
                path: 'table',
                name: '//analytics/absence/table',
                component: () => import('/app/src/pages/index/analytics/absence/table.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'health',
            /* internal name: '//analytics/health' */
            /* no component */
            children: [
              {
                path: 'dashboard',
                name: '//analytics/health/dashboard',
                component: () => import('/app/src/pages/index/analytics/health/dashboard.vue'),
                /* no children */
              },
              {
                path: 'details',
                name: '//analytics/health/details',
                component: () => import('/app/src/pages/index/analytics/health/details.vue'),
                /* no children */
              },
              {
                path: 'table',
                name: '//analytics/health/table',
                component: () => import('/app/src/pages/index/analytics/health/table.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'hr',
            /* internal name: '//analytics/hr' */
            /* no component */
            children: [
              {
                path: 'details',
                name: '//analytics/hr/details',
                component: () => import('/app/src/pages/index/analytics/hr/details.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'survey',
            /* internal name: '//analytics/survey' */
            /* no component */
            children: [
              {
                path: ':survey',
                name: '//analytics/survey/[survey]',
                component: () => import('/app/src/pages/index/analytics/survey/[survey].vue'),
                /* no children */
              },
              {
                path: 'dashboard',
                name: '//analytics/survey/dashboard',
                component: () => import('/app/src/pages/index/analytics/survey/dashboard.vue'),
                /* no children */
              },
              {
                path: 'list',
                name: '//analytics/survey/list',
                component: () => import('/app/src/pages/index/analytics/survey/list.vue'),
                /* no children */
              },
              {
                path: 'survey-responses-list',
                name: '//analytics/survey/survey-responses-list',
                component: () => import('/app/src/pages/index/analytics/survey/survey-responses-list.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'availability',
        /* internal name: '//availability' */
        /* no component */
        children: [
          {
            path: '',
            name: '//availability/',
            component: () => import('/app/src/pages/index/availability/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'chat',
        /* internal name: '//chat' */
        /* no component */
        children: [
          {
            path: '',
            name: '//chat/',
            component: () => import('/app/src/pages/index/chat/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'clients',
        /* internal name: '//clients' */
        /* no component */
        children: [
          {
            path: '',
            name: '//clients/',
            component: () => import('/app/src/pages/index/clients/index.vue'),
            /* no children */
          },
          {
            path: ':client',
            /* internal name: '//clients/[client]' */
            /* no component */
            children: [
              {
                path: 'action-list',
                /* internal name: '//clients/[client]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/action-list/',
                    component: () => import('/app/src/pages/index/clients/[client]/action-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'activity-form',
                name: '//clients/[client]/activity-form',
                component: () => import('/app/src/pages/index/clients/[client]/activity-form.vue'),
                /* no children */
              },
              {
                path: 'activity-list',
                /* internal name: '//clients/[client]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/activity-list/',
                    component: () => import('/app/src/pages/index/clients/[client]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'client-information',
                name: 'client-information',
                component: () => import('/app/src/pages/index/clients/[client]/client-information.vue'),
                /* no children */
              },
              {
                path: 'current-absence',
                /* internal name: '//clients/[client]/current-absence' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/current-absence/',
                    component: () => import('/app/src/pages/index/clients/[client]/current-absence/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'departments',
                /* internal name: '//clients/[client]/departments' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/departments/',
                    component: () => import('/app/src/pages/index/clients/[client]/departments/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'divisions',
                /* internal name: '//clients/[client]/divisions' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/divisions/',
                    component: () => import('/app/src/pages/index/clients/[client]/divisions/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'employers',
                /* internal name: '//clients/[client]/employers' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/employers/',
                    component: () => import('/app/src/pages/index/clients/[client]/employers/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'file-access',
                /* internal name: '//clients/[client]/file-access' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/file-access/',
                    component: () => import('/app/src/pages/index/clients/[client]/file-access/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//clients/[client]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/clients/[client]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'persons',
                /* internal name: '//clients/[client]/persons' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/persons/',
                    component: () => import('/app/src/pages/index/clients/[client]/persons/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'view',
                name: '//clients/[client]/view',
                component: () => import('/app/src/pages/index/clients/[client]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'contacts',
        /* internal name: '//contacts' */
        /* no component */
        children: [
          {
            path: '',
            name: '//contacts/',
            component: () => import('/app/src/pages/index/contacts/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'departments',
        /* internal name: '//departments' */
        /* no component */
        children: [
          {
            path: '',
            name: '//departments/',
            component: () => import('/app/src/pages/index/departments/index.vue'),
            /* no children */
          },
          {
            path: ':department',
            /* internal name: '//departments/[department]' */
            /* no component */
            children: [
              {
                path: 'action-list',
                /* internal name: '//departments/[department]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/action-list/',
                    component: () => import('/app/src/pages/index/departments/[department]/action-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'activity-form',
                name: '//departments/[department]/activity-form',
                component: () => import('/app/src/pages/index/departments/[department]/activity-form.vue'),
                /* no children */
              },
              {
                path: 'activity-list',
                /* internal name: '//departments/[department]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/activity-list/',
                    component: () => import('/app/src/pages/index/departments/[department]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'current-absence',
                /* internal name: '//departments/[department]/current-absence' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/current-absence/',
                    component: () => import('/app/src/pages/index/departments/[department]/current-absence/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'detail',
                name: '//departments/[department]/detail',
                component: () => import('/app/src/pages/index/departments/[department]/detail.vue'),
                /* no children */
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//departments/[department]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/departments/[department]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'persons',
                /* internal name: '//departments/[department]/persons' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/persons/',
                    component: () => import('/app/src/pages/index/departments/[department]/persons/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'view',
                name: '//departments/[department]/view',
                component: () => import('/app/src/pages/index/departments/[department]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'divisions',
        /* internal name: '//divisions' */
        /* no component */
        children: [
          {
            path: '',
            name: '//divisions/',
            component: () => import('/app/src/pages/index/divisions/index.vue'),
            /* no children */
          },
          {
            path: ':division',
            /* internal name: '//divisions/[division]' */
            /* no component */
            children: [
              {
                path: 'action-list',
                /* internal name: '//divisions/[division]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/action-list/',
                    component: () => import('/app/src/pages/index/divisions/[division]/action-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'activity-form',
                name: '//divisions/[division]/activity-form',
                component: () => import('/app/src/pages/index/divisions/[division]/activity-form.vue'),
                /* no children */
              },
              {
                path: 'activity-list',
                /* internal name: '//divisions/[division]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/activity-list/',
                    component: () => import('/app/src/pages/index/divisions/[division]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'current-absence',
                /* internal name: '//divisions/[division]/current-absence' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/current-absence/',
                    component: () => import('/app/src/pages/index/divisions/[division]/current-absence/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'departments',
                /* internal name: '//divisions/[division]/departments' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/departments/',
                    component: () => import('/app/src/pages/index/divisions/[division]/departments/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'detail',
                name: '//divisions/[division]/detail',
                component: () => import('/app/src/pages/index/divisions/[division]/detail.vue'),
                /* no children */
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//divisions/[division]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/divisions/[division]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'persons',
                /* internal name: '//divisions/[division]/persons' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/persons/',
                    component: () => import('/app/src/pages/index/divisions/[division]/persons/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'view',
                name: '//divisions/[division]/view',
                component: () => import('/app/src/pages/index/divisions/[division]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'documents',
        /* internal name: '//documents' */
        /* no component */
        children: [
          {
            path: '',
            name: '//documents/',
            component: () => import('/app/src/pages/index/documents/index.vue'),
            /* no children */
          },
          {
            path: ':document',
            /* internal name: '//documents/[document]' */
            /* no component */
            children: [
              {
                path: 'view',
                name: '//documents/[document]/view',
                component: () => import('/app/src/pages/index/documents/[document]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'employers',
        /* internal name: '//employers' */
        /* no component */
        children: [
          {
            path: '',
            name: '//employers/',
            component: () => import('/app/src/pages/index/employers/index.vue'),
            /* no children */
          },
          {
            path: ':employer',
            /* internal name: '//employers/[employer]' */
            /* no component */
            children: [
              {
                path: 'action-list',
                /* internal name: '//employers/[employer]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/action-list/',
                    component: () => import('/app/src/pages/index/employers/[employer]/action-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'activity-form',
                name: '//employers/[employer]/activity-form',
                component: () => import('/app/src/pages/index/employers/[employer]/activity-form.vue'),
                /* no children */
              },
              {
                path: 'activity-list',
                /* internal name: '//employers/[employer]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/activity-list/',
                    component: () => import('/app/src/pages/index/employers/[employer]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'current-absence',
                /* internal name: '//employers/[employer]/current-absence' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/current-absence/',
                    component: () => import('/app/src/pages/index/employers/[employer]/current-absence/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'departments',
                /* internal name: '//employers/[employer]/departments' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/departments/',
                    component: () => import('/app/src/pages/index/employers/[employer]/departments/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'divisions',
                /* internal name: '//employers/[employer]/divisions' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/divisions/',
                    component: () => import('/app/src/pages/index/employers/[employer]/divisions/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'employer-information',
                name: 'employer-dashboard',
                component: () => import('/app/src/pages/index/employers/[employer]/employer-information.vue'),
                /* no children */
              },
              {
                path: 'file-access',
                /* internal name: '//employers/[employer]/file-access' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/file-access/',
                    component: () => import('/app/src/pages/index/employers/[employer]/file-access/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//employers/[employer]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/employers/[employer]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'persons',
                /* internal name: '//employers/[employer]/persons' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/persons/',
                    component: () => import('/app/src/pages/index/employers/[employer]/persons/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'view',
                name: '//employers/[employer]/view',
                component: () => import('/app/src/pages/index/employers/[employer]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'invoiced-activities',
        /* internal name: '//invoiced-activities' */
        /* no component */
        children: [
          {
            path: '',
            name: '//invoiced-activities/',
            component: () => import('/app/src/pages/index/invoiced-activities/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'meeting',
        /* internal name: '//meeting' */
        /* no component */
        children: [
          {
            path: ':meeting',
            /* internal name: '//meeting/[meeting]' */
            /* no component */
            children: [
              {
                path: '',
                name: '//meeting/[meeting]/',
                component: () => import('/app/src/pages/index/meeting/[meeting]/index.vue'),
                /* no children */
              },
              {
                path: 'start',
                /* internal name: '//meeting/[meeting]/start' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//meeting/[meeting]/start/',
                    component: () => import('/app/src/pages/index/meeting/[meeting]/start/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'my-calendar',
        /* internal name: '//my-calendar' */
        /* no component */
        children: [
          {
            path: '',
            name: '//my-calendar/',
            component: () => import('/app/src/pages/index/my-calendar/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'notifications',
        /* internal name: '//notifications' */
        /* no component */
        children: [
          {
            path: '',
            name: '//notifications/',
            component: () => import('/app/src/pages/index/notifications/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'persons',
        /* internal name: '//persons' */
        /* no component */
        children: [
          {
            path: '',
            name: '//persons/',
            component: () => import('/app/src/pages/index/persons/index.vue'),
            /* no children */
          },
          {
            path: ':person',
            /* internal name: '//persons/[person]' */
            /* no component */
            children: [
              {
                path: 'absence-history',
                name: '//persons/[person]/absence-history',
                component: () => import('/app/src/pages/index/persons/[person]/absence-history.vue'),
                /* no children */
              },
              {
                path: 'action-list',
                /* internal name: '//persons/[person]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/action-list/',
                    component: () => import('/app/src/pages/index/persons/[person]/action-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'activity-form',
                name: '//persons/[person]/activity-form',
                component: () => import('/app/src/pages/index/persons/[person]/activity-form.vue'),
                /* no children */
              },
              {
                path: 'activity-list',
                /* internal name: '//persons/[person]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/activity-list/',
                    component: () => import('/app/src/pages/index/persons/[person]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'employment',
                /* internal name: '//persons/[person]/employment' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/employment/',
                    component: () => import('/app/src/pages/index/persons/[person]/employment/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':employment',
                    /* internal name: '//persons/[person]/employment/[employment]' */
                    /* no component */
                    children: [
                      {
                        path: 'edit',
                        name: '//persons/[person]/employment/[employment]/edit',
                        component: () => import('/app/src/pages/index/persons/[person]/employment/[employment]/edit.vue'),
                        /* no children */
                      },
                      {
                        path: 'view',
                        name: '//persons/[person]/employment/[employment]/view',
                        component: () => import('/app/src/pages/index/persons/[person]/employment/[employment]/view.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'create',
                    name: '//persons/[person]/employment/create',
                    component: () => import('/app/src/pages/index/persons/[person]/employment/create.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'file-access',
                /* internal name: '//persons/[person]/file-access' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/file-access/',
                    component: () => import('/app/src/pages/index/persons/[person]/file-access/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'health',
                name: '//persons/[person]/health',
                component: () => import('/app/src/pages/index/persons/[person]/health.vue'),
                /* no children */
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//persons/[person]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/persons/[person]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'person-information',
                name: 'person-information',
                component: () => import('/app/src/pages/index/persons/[person]/person-information.vue'),
                /* no children */
              },
              {
                path: 'view',
                name: '//persons/[person]/view',
                component: () => import('/app/src/pages/index/persons/[person]/view.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'create',
            name: '//persons/create',
            component: () => import('/app/src/pages/index/persons/create.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'profile-information',
        /* internal name: '//profile-information' */
        /* no component */
        children: [
          {
            path: '',
            name: '//profile-information/',
            component: () => import('/app/src/pages/index/profile-information/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'settings',
        /* internal name: '//settings' */
        /* no component */
        children: [
          {
            path: '',
            name: '//settings/',
            component: () => import('/app/src/pages/index/settings/index.vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": true
    }
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/:all(.*)',
    name: '404',
    component: () => import('/app/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  _definePage_default_3
  ),
  _mergeRouteRecord(
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/app/src/pages/auth.vue'),
    children: [
      {
        path: 'forgot-password',
        name: '/auth/forgot-password',
        component: () => import('/app/src/pages/auth/forgot-password.vue'),
        /* no children */
      },
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('/app/src/pages/auth/login.vue'),
        /* no children */
        meta: {
          "requiresAuth": false
        }
      },
      {
        path: 'reset-password',
        name: '/auth/reset-password',
        component: () => import('/app/src/pages/auth/reset-password.vue'),
        /* no children */
      },
      {
        path: 'verify-email',
        name: '/auth/verify-email',
        component: () => import('/app/src/pages/auth/verify-email.vue'),
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": false
    }
  },
  _definePage_default_4
  ),
  {
    path: '/sso',
    name: '/sso',
    component: () => import('/app/src/pages/sso.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

