<script setup lang="ts">
import { defineComponent, onMounted, ref, inject } from 'vue'
import { definePage } from 'vue-router/auto'
import ActionListCount from '~/components/dashboards/widgets/ActionListCount.vue'
import ClientCount from '~/components/dashboards/widgets/ClientCount.vue'
import DepartmentCount from '~/components/dashboards/widgets/DepartmentCount.vue'
import DivisionCount from '~/components/dashboards/widgets/DivisionCount.vue'
import EmployerCount from '~/components/dashboards/widgets/EmployerCount.vue'
import PersonCount from '~/components/dashboards/widgets/PersonCount.vue'
import { useAuthorisationLevelService } from '~/services/auth/useAuthorisationLevelService'
import { useDashboardService } from '~/services/dashboard/useDashboardService'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth/AuthStore'
import DocumentCount from '~/components/dashboards/widgets/DocumentCount.vue'
import AppointmentsWidget from '~/components/dashboards/widgets/AppointmentsWidget.vue'
import { createSwapy, type Swapy } from 'swapy'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { mainDashboardOrder } from '~/utils/constants'
import { useWidgetOrderService } from '~/services/widgetOrder/useWidgetOrderService'
import SwapyHiddenWidget from '~/components/utils/SwapyHiddenWidget.vue'
import type { OrderItems } from '~/ts/interfaces/services/orderItems.interface'
import type { DashboardItem } from '~/ts/globals'

definePage({
  name: 'main-dashboard',
  meta: {
    requiresAuth: true
  }
})

export interface OrderItemsPayload {
  orderItems: string[]
}

defineComponent({
  name: 'HomePage'
})
const swapper = ref<HTMLDivElement | null>(null)
const swapy = ref<Swapy | null>(null)
const dashboardItems = ref<DashboardItem[]>([])
const { onSubmitWidgetOrder, fetchWidgetOrder } = useWidgetOrderService()
const widgetOrderData = ref<OrderItems>()

const { authUser } = storeToRefs(useAuthStore())
const { dashboardData, init } = useDashboardService()
const {
  authorizationLevel,
  hasGroupPermission,
  clientAllowedAuthorizationLevels,
  employerAllowedAuthorizationLevels,
  divisionAllowedAuthorizationLevels,
  departmentAllowedAuthorizationLevels,
  personAllowedAuthorizationLevels
} = useAuthorisationLevelService()

onMounted(async (): Promise<void> => {
  if (!authUser.value) return
  await init()
  widgetOrderData.value = await fetchWidgetOrder('main-dashboard')
  await initSwapy()
  await populateDashboardItems()
})

const initSwapy = () => {
  if (swapy.value) {
    swapy.value.destroy()
  }

  if (swapper.value) {
    swapy.value = createSwapy(swapper.value)
    swapy.value.onSwapEnd(({ data }) => {
      const orderItemsArray: string[] = Object.keys(data.object)
        .map((key) => data.object[key] as string)
        .filter((widgetName) => widgetName !== null)

      if (authUser && authUser.value && orderItemsArray.length > 0) {
        const payload: OrderItemsPayload = { orderItems: orderItemsArray }
        const missingItems = mainDashboardOrder.filter((item) => !payload.orderItems.includes(item))
        payload.orderItems = [...missingItems, ...payload.orderItems]
        onSubmitWidgetOrder('main-dashboard', payload)
      }
    })
  }
}

onBeforeRouteLeave((to, from, next) => {
  if (swapy.value) {
    swapy.value.destroy()
  }
  next()
})

const router = useRouter()

const navigateTo = (url: string) => {
  router.push(url)
}

const $can = inject<(permission: string) => boolean>('$can')

const populateDashboardItems = () => {
  const itemsMap: Record<string, DashboardItem> = {
    client_count: {
      condition: () =>
        !!authorizationLevel.value &&
        clientAllowedAuthorizationLevels.includes(authorizationLevel.value) &&
        !!$can &&
        $can('clients.view'),
      path: '/clients',
      item: 'client_count',
      component: ClientCount,
      props: { clientCount: dashboardData.value?.contractors_count || 0 }
    },
    employer_count: {
      condition: () =>
        !!authorizationLevel.value &&
        employerAllowedAuthorizationLevels.includes(authorizationLevel.value) &&
        !!$can &&
        $can('employers.view'),
      path: '/employers',
      item: 'employer_count',
      component: EmployerCount,
      props: { employerCount: dashboardData.value?.employers_count || 0 }
    },

    division_count: {
      condition: () =>
        !!authorizationLevel.value &&
        divisionAllowedAuthorizationLevels.includes(authorizationLevel.value) &&
        !!$can &&
        $can('divisions.view'),
      path: '/divisions',
      item: 'division_count',
      component: DivisionCount,
      props: { divisionCount: dashboardData.value?.divisions_count || 0 }
    },

    department_count: {
      condition: () =>
        !!authorizationLevel.value &&
        departmentAllowedAuthorizationLevels.includes(authorizationLevel.value) &&
        !!$can &&
        $can('departments.view'),
      path: '/departments',
      item: 'department_count',
      component: DepartmentCount,
      props: { departmentCount: dashboardData.value?.departments_count || 0 }
    },

    person_count: {
      condition: () =>
        !!authorizationLevel.value &&
        personAllowedAuthorizationLevels.includes(authorizationLevel.value) &&
        !!$can &&
        $can('persons.view'),
      path: '/persons',
      item: 'person_count',
      component: PersonCount,
      props: { personCount: dashboardData.value?.persons_count || 0 }
    },

    action_list_count: {
      condition: () => !!authorizationLevel.value && !!$can && $can('actionList.view'),
      path: '/action-list',
      item: 'action_list_count',
      component: ActionListCount,
      props: { actionListCount: dashboardData.value?.action_list_count || 0, title: 'Action List' }
    },

    activity_list_count: {
      condition: () => !!authorizationLevel.value && !!$can && $can('activityList.view'),
      path: '/activity-list',
      item: 'activity_list_count',
      component: ActionListCount,
      props: {
        actionListCount: dashboardData.value?.activity_list_count || 0,
        title: 'Activity List'
      }
    },

    invoiced_activity_list_count: {
      condition: () => !!authorizationLevel.value && !!$can && $can('invoicedActivities.view'),
      path: '/invoiced-activities',
      item: 'invoiced_activity_list_count',
      component: ActionListCount,
      props: {
        actionListCount: dashboardData.value?.invoiced_activity_list_count || 0,
        title: 'Invoiced Activities'
      }
    },

    documents_count: {
      condition: () => !!authorizationLevel.value && !!$can && $can('documents.view'),
      path: '/documents',
      item: 'documents_count',
      component: DocumentCount,
      props: { documentCount: dashboardData.value?.documents_count || 0 }
    }
  }

  if (widgetOrderData.value && widgetOrderData.value.orderItems.length > 0) {
    dashboardItems.value = widgetOrderData.value.orderItems
      .map((orderKey: string) => {
        const item = itemsMap[orderKey as keyof typeof itemsMap]
        if (item) {
          return item
        }
        return null
      })
      .filter((item: DashboardItem | null): item is DashboardItem => item !== null)
  } else {
    dashboardItems.value = mainDashboardOrder.map(
      (orderKey) => itemsMap[orderKey as keyof typeof itemsMap]
    )
  }
}
</script>

<template>
  <div class="swapper" ref="swapper">
    <div v-if="!authorizationLevel" class="flex items-center justify-center h-[calc(100vh-16rem)]">
      <p class="font-semibold text-xl">{{ $t('No authorization level is set') }}</p>
    </div>
    <div
      v-else-if="!hasGroupPermission"
      class="flex items-center justify-center h-[calc(100vh-16rem)]"
    >
      <p class="font-semibold text-xl">{{ $t('No permission is set') }}</p>
    </div>
    <div
      v-else-if="dashboardData"
      class="grid gap-5 md:grid-cols-2 my-5 mx-auto"
      :class="['lg:grid-cols-5']"
    >
      <template v-for="(item, index) in dashboardItems" :key="index">
        <div
          v-if="item.condition()"
          :data-swapy-slot="(index + 1).toString()"
          class="cursor-pointer"
        >
          <div :data-swapy-item="item.item" @click="navigateTo(item.path)">
            <component :is="item.component" v-bind="item.props"></component>
          </div>
        </div>
      </template>
    </div>
    <SwapyHiddenWidget :item="'hidden'" :slotNode="99"></SwapyHiddenWidget>
  </div>
  <div v-if="$can('appointments.view')">
    <AppointmentsWidget></AppointmentsWidget>
  </div>
</template>

<route lang="json">
{
  "name": "main-dashboard",
  "meta": {
    "requiresAuth": true
  }
}
</route>
